/* eslint-disable no-console */

'use client';

import Script from 'next/script';
import { getAuth } from 'firebase/auth';
import { getUserId } from '@/utils/cookies';
import { firebaseApp } from '@/utils/firebase';
import { type FC, useMemo, useEffect } from 'react';
import { useSiteContext } from '@/context/SiteContext';
import { LoginProvider, useLoginContext } from '@/context/LoginContext';

import type {
	PushlyProps,
	CommentsProfileData,
	UserSubscriptionPreferencesData,
} from './types/pushly';
import {
	pushly,
	getPlanCodes,
	isPayingSubscriber,
	isNewsletterSubscriber,
	getNewsletterSubscriptions,
} from './util/util';

async function fetchCommentsProfile(
	rgis: string,
): Promise<CommentsProfileData> {
	const response = await fetch(
		`/.api/memberships/comments-profile?rgis=${encodeURIComponent(rgis)}`,
	);
	if (!response.ok) {
		const err = new Error(`${response.status}`);
		throw err;
	}

	return response.json();
}

async function fetchUserSubscriptionsPreferences(
	rgis: string,
): Promise<UserSubscriptionPreferencesData> {
	const response = await fetch(
		`/.api/memberships/user-subscription-preferences?rgis=${encodeURIComponent(rgis)}`,
	);
	if (!response.ok) {
		const err = new Error(`${response.status}`);
		throw err;
	}

	return response.json();
}

const PushlyComponent: FC<PushlyProps> = ({ config, siteKeyword }) => {
	const cdnSrc = `https://cdn.p-n.io/pushly-sdk.min.js?domain_key=${config?.domainKey}`;
	const { rgisCookie } = useLoginContext();

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/sw.js')
				.then((registration) => {
					console.log(
						'Service Worker registered with scope:',
						registration.scope,
					);
				})
				.catch((error) => {
					console.error('Service Worker registration failed:', error);
				});
		}
	}, [config?.domainKey, siteKeyword]);

	useEffect(() => {
		const userId = getUserId(rgisCookie);
		const isLoggedInUser = userId !== '' && !Number.isNaN(Number(userId));

		// The api route fetch functions expect a string for the RGIS cookie
		// to silence TS errors, this check ensures the RGIS
		// cookie is defined before calling in fetch functions
		if (isLoggedInUser) {
			// RGIS cookie when used in fetch request throws a 401 when surrounded by ""
			// replaces "" with '' to authenticate fetch request, while also ensuring this variable
			// is only called when the rgisCookie exists
			const rgisCookieVal = (rgisCookie as string).replace(/"/g, '');

			// comments profile fetch
			fetchCommentsProfile(rgisCookieVal).then((userInfo) => {
				pushly('external_id', userId);
				pushly('profile', {
					is_logged_in_user: isLoggedInUser,
					is_paying_subscriber: isPayingSubscriber(userInfo),
					sub_planCodes: getPlanCodes(userInfo),
				});
			});

			// newsletter profile fetch
			fetchUserSubscriptionsPreferences(rgisCookieVal).then((userInfo) => {
				const isNewsletterSub = isNewsletterSubscriber(userInfo);
				pushly('profile', {
					is_newsletter_subscriber: isNewsletterSub,
					newsletter_subs_list: getNewsletterSubscriptions(userInfo),
				});
			});
		}

		pushly('profile', {
			is_logged_in_user: isLoggedInUser,
		});

		pushly('load', { domainKey: config?.domainKey, sw: '/sw.js' });
	}, [rgisCookie, config?.domainKey, siteKeyword]);

	return <Script src={cdnSrc} />;
};

export const PushlyWrapper: FC = () => {
	const { config } = useSiteContext();
	const { firebase } = useSiteContext();
	const auth = useMemo(() => {
		return getAuth(firebaseApp(firebase.config));
	}, [firebase]);
	const pushlyConfig = config.analytics?.providers?.Pushly;
	const isEnabled = pushlyConfig?.isEnabled;
	return (
		isEnabled && (
			<LoginProvider auth={auth}>
				<PushlyComponent
					config={pushlyConfig}
					siteKeyword={config.siteKeyword}
				/>
			</LoginProvider>
		)
	);
};
