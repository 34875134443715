'use client';

import { GDPR } from '@/components/GDPR';
import type { Post } from '@/types/entities';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import { useState, useEffect, type ReactNode } from 'react';

import { loadScript } from '../load-script';
import { ARTICLE_ENTER, eventsFilterByType } from '../events';
import type { AppSettings } from '../data/fetch-app-settings';
import { serializeExperiments } from '../serialize-experiments';
import { useFetchAppSettings } from '../hooks/use-fetch-app-settings';

const getMetaData = (currentPost: Partial<Post>) => {
	return {
		author: currentPost.authors?.[0]?.name,
		contentId: currentPost.meta?.tempest_id,
		pageType: currentPost.meta?.page_type,
		primaryCategory: currentPost.primaryCategory?.name,
	};
};

const TrackonomicsBootstrap = (): ReactNode => {
	const { experiments, viewCorrelator } = useAdService();
	const appSettings = useFetchAppSettings();
	const siteSettings = appSettings?.settings as AppSettings['settings'];
	const { config } = useSiteContext();
	const { channelKey, siteId } = config;
	const [linkWrappingEnabled, setLinkWrappingEnabled] = useState(
		siteSettings?.analytics?.trackonomics?.link_wrapping,
	);
	const [funnelRelayEnabled, setFunnelRelayEnabled] = useState(
		siteSettings?.analytics?.trackonomics?.funnel_relay,
	);
	const [currentPost, setCurrentPost] = useState<null | Partial<Post>>(null);

	const linkWrapping = siteSettings?.analytics?.trackonomics?.link_wrapping;
	const funnelRelay = siteSettings?.analytics.trackonomics?.funnel_relay;
	const trackonomicsSiteId = siteSettings?.analytics?.trackonomics?.site_id;

	useEffect(() => {
		function setGlobalData(data: {
			author?: string;
			contentId?: string;
			pageType?: string;
			primaryCategory?: string;
		}) {
			// Set global data needed by Funnel Relay script
			window.phxCommerce = {
				trackonomics: {
					author: data.author ?? '',
					mavenChannel: channelKey,
					pageType: data.pageType ?? '',
					section: data?.primaryCategory ?? '',
					siteId: siteId ?? '',
					tempestContentId: data.contentId ?? '',
				},
			};
			window.mavenCorrelator = viewCorrelator;
			window.phxPageTarget = {
				config: {
					global: {
						baseModel: {
							experiments: serializeExperiments(experiments),
						},
					},
				},
			};
		}
		setLinkWrappingEnabled(linkWrapping);
		setFunnelRelayEnabled(funnelRelay);

		const subscription = eventsFilterByType(ARTICLE_ENTER).subscribe(
			({ value: post }) => {
				setCurrentPost(post);
				const { author, contentId, pageType, primaryCategory } =
					getMetaData(post);
				setGlobalData({ author, contentId, pageType, primaryCategory });
			},
		);
		return () => {
			subscription.unsubscribe();
		};
	}, [
		channelKey,
		currentPost,
		experiments,
		funnelRelay,
		linkWrapping,
		siteId,
		viewCorrelator,
	]);

	const funnelRelayScriptUrl =
		siteSettings?.analytics?.trackonomics?.script_url;
	if (funnelRelayScriptUrl && linkWrappingEnabled && funnelRelayEnabled) {
		const trackonomicsPropertyId =
			siteSettings?.analytics?.trackonomics?.property_id;

		const funnelRelayReloadScript = `if (window.trx) { window.trx.magicLinksEngine = new window.trx.MagicLinks(false);window.trx.magicLinksEngine.run(true); }`;

		loadScript(
			funnelRelayScriptUrl,
			{
				async: true,
				'data-customer-id': trackonomicsSiteId,
				'data-property-id': trackonomicsPropertyId,
				id: 'funnel-relay-installer',
			},
			true,
			'footer',
		);

		loadScript(
			funnelRelayReloadScript,
			{ async: true, id: 'funnel-relay-reload' },
			false,
			'footer',
		);
	}
	return null;
};

export const Trackonomics = () => {
	return (
		<GDPR purposes={['measurement/content']} vendorId="trackonomics">
			<TrackonomicsBootstrap />
		</GDPR>
	);
};
