'use client';

import { loadScript } from '@/utils/load-script';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import type { Post, PermutiveIabTerm } from '@/types/entities';
import { getPostCategories } from '@/utils/get-post-categories';
import { getIabTermsTextAndSim } from '@/utils/get-post-iab-terms';
import { useRef, useState, useEffect, type ReactNode } from 'react';
import { eventsFilterByType, ANALYTICS_PAGE_VIEW } from '@/utils/events';
import { collectIdentities } from '@/utils/analytics/tracking-identities';
import { useCanRender, useConsentContext } from '@/context/ConsentContext';

interface Page {
	articleId?: number;
	author?: string;
	classifications_watson: {
		categories: string;
		concepts: string;
		emotion: string;
		entities: string;
		keywords: string;
		sentiment: string;
		taxonomy_labels: string;
	};
	correlator?: number;
	iab3Data: PermutiveIabTerm[];
	sections?: string[];
	subSections?: string[];
	terms?: string[];
}

const pageViewEventSentPageId = new Set();
export const PermutiveBootstrap = (): ReactNode => {
	const { config } = useSiteContext();
	const { viewCorrelator } = useAdService();
	const permutiveConfig = config.analytics?.providers?.Permutive || {
		enabled: false,
		organizationId: '',
		workspaceApiKey: '',
		workspaceId: '',
	};
	const PERMUTIVE_CLIENT_SCRIPT = `https://${permutiveConfig.organizationId}.edge.permutive.app/${permutiveConfig.workspaceId}-web.js`;

	const { enabled, workspaceApiKey, workspaceId } = permutiveConfig;
	const hasInitialized = useRef(false);
	const [currentPost, setCurrentPost] = useState<null | Partial<Post>>(null);
	const [permutiveReady, setPermutiveReady] = useState(false);
	const { isGDPRApplicable } = useConsentContext();
	const { arenaContext } = useConsentContext();
	const isConsentGiven = useCanRender({
		purposes: ['storage', 'advertising/basic'],
		vendorId: 'permutive',
	});

	useEffect(() => {
		const subscription = eventsFilterByType(ANALYTICS_PAGE_VIEW).subscribe(
			({ value: data }) => {
				if (!enabled) return;
				setCurrentPost(data.post);
			},
		);
		return () => {
			subscription.unsubscribe();
		};
	}, [enabled]);

	useEffect(() => {
		if (!enabled || !permutiveReady || !isGDPRApplicable) {
			return;
		}
		if (isConsentGiven) {
			window.permutive.consent({
				opt_in: true,
				token: 'CONSENT_GIVEN',
			});
		} else {
			window.permutive.consent({
				opt_in: false,
			});
		}
	}, [isConsentGiven, enabled, isGDPRApplicable, permutiveReady]);

	useEffect(() => {
		if (
			enabled &&
			permutiveReady &&
			currentPost &&
			!pageViewEventSentPageId.has(currentPost.id)
		) {
			pageViewEventSentPageId.add(currentPost.id);
			const { additional, primary } = getPostCategories(currentPost);
			const author =
				(currentPost.meta?.author_profile_id ?? []).length > 0
					? `tm-${currentPost.meta?.author_profile_id![0]}`
					: undefined;
			const terms = (currentPost.tags ?? [])
				.filter((tag) => tag.taxonomy === 'post_tag')
				.map((tag) => tag.name);
			const page: Page = {
				articleId: currentPost.id,
				author,
				classifications_watson: {
					categories: '$alchemy_taxonomy',
					concepts: '$alchemy_concepts',
					emotion: '$alchemy_document_emotion',
					entities: '$alchemy_entities',
					keywords: '$alchemy_keywords',
					sentiment: '$alchemy_document_sentiment',
					taxonomy_labels: '$alchemy_taxonomy_labels',
				},
				correlator: viewCorrelator,
				iab3Data: getIabTermsTextAndSim(currentPost),
				terms,
			};
			if (primary) {
				page.sections = [primary.slug];
			}
			if (additional) {
				page.subSections = additional.map((c) => c.slug);
			}
			window.permutive.addon('web', {
				page,
			});
		}
	}, [enabled, permutiveReady, currentPost, viewCorrelator]);

	if (arenaContext && enabled && !hasInitialized.current) {
		hasInitialized.current = true;
		const handleLoad = () => {
			window.identityFeatureFlags = window.identityFeatureFlags || {};
			window.permutive.ready(() => {
				collectIdentities()
					.then((identities: Identity[]) => {
						if (identities.length > 0) {
							window.permutive.identify(identities);
						}
					})
					.finally(() => setPermutiveReady(true));
			}, 'initialised');
		};

		const permutiveInitScript = `!function(e,o,n,i){if(!e){e=e||{},window.permutive=e,e.q=[];var t=function(){return([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,function(e){return(e^(window.crypto||window.msCrypto).getRandomValues(new Uint8Array(1))[0]&15>>e/4).toString(16)})};e.config=i||{},e.config.apiKey=o,e.config.workspaceId=n,e.config.environment=e.config.environment||"production",(window.crypto||window.msCrypto)&&(e.config.viewId=t());for(var g=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<g.length;r++){var w=g[r];e[w]=function(o){return function(){var n=Array.prototype.slice.call(arguments,0);e.q.push({functionName:o,arguments:n})}}(w)}}}(window.permutive, '${workspaceApiKey}', '${workspaceId}',{'consentRequired': ${isGDPRApplicable}});window.googletag=window.googletag||{},window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var e=window.localStorage?.getItem?.("_pdfps");window.googletag.pubads().setTargeting("permutive",e?JSON.parse(e):[]);var o=window.localStorage?.getItem?.("permutive-id");o&&(window.googletag.pubads().setTargeting("puid",o),window.googletag.pubads().setTargeting("ptime",Date.now().toString())),window.permutive.config.viewId&&window.googletag.pubads().setTargeting("prmtvvid",window.permutive.config.viewId),window.permutive.config.workspaceId&&window.googletag.pubads().setTargeting("prmtvwid",window.permutive.config.workspaceId)}});`;

		loadScript(permutiveInitScript, { id: 'permutive-init' }, false, 'header');
		loadScript(
			PERMUTIVE_CLIENT_SCRIPT,
			{ async: true, id: 'permutive-main' },
			true,
			'header',
			handleLoad,
		);
	}

	return null;
};
